import React, { useEffect } from "react"
import { navigate } from "gatsby";
import { PageLayout, Logo, LoadingSpinner } from "../components/components";
import { Leaderboard } from "./clue-master/game/Leaderboard";
import useFirebaseGame from "../hooks/useFirebaseGame";
import useFirebaseTeams from "../hooks/useFirebaseTeams";
import { StringParam, useQueryParam } from "use-query-params";
import useIsLoading from "../hooks/useIsLoading";
import useGameLogo from "../hooks/useGameLogo";

const ReadOnlyLeaderboard = () => {
  const [gameID] = useQueryParam("gameId", StringParam);
  const { game, isLoading: isLoadingGame } = useFirebaseGame(gameID);
  const { teams, isLoading: isLoadingTeams } = useFirebaseTeams(gameID);
  const { isLoading, setIsLoading } = useIsLoading(isLoadingGame || isLoadingTeams);
  const {smallLogo} = useGameLogo({useGame: game});

  useEffect(() => {
    if (!isLoadingGame && !game) {
      alert(`Game does not exist`);
      navigate('/');
      return;
    }

    if (!isLoadingGame && !isLoadingTeams) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [isLoadingGame, isLoadingTeams, game, setIsLoading])

  return (
    <PageLayout>
      <Logo className="mb-4" src={smallLogo} style={{width: '100%'}}/>
      <LoadingSpinner isLoading={isLoading} hideChildrenWhenLoading>
        <Leaderboard game={game} teams={teams} readonly/>
      </LoadingSpinner>
    </PageLayout>
  )
}

export default ReadOnlyLeaderboard;
